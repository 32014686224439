<template>
  <div class="deviceManagerList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="导入通用内容并关联分类" permission="commonImport" @click="importCommon"></v-button>
        <v-button text="导入维保单位并关联分类" permission="unitImport" @click="importMaintain"></v-button>
        <v-button text="配置分类" permission="categoryConfig" @click="configClassify"></v-button>
        <v-button text="关联通用内容" permission="relevanceCommon" @click="relate"></v-button>
      </template>
      <template #searchSlot>
        <v-select2 label="一级分类" v-model="searchParams.firstLevel" v-bind="firstLevelParams"></v-select2>
        <v-select2 label="二级分类" v-model="searchParams.secondLevel" v-bind="secondLevelParams"></v-select2>
        <v-select2 label="三级分类" v-model="searchParams.thirdLevel" v-bind="thirdLevelParams"></v-select2>
        <v-datepicker label="创建时间段" :startTime.sync="searchParams.startDate" :endTime.sync="searchParams.endDate"></v-datepicker>
        <!-- <v-input label="创建人" v-model="searchParams.userName" />
        <v-input-number label="关联设备(个数)" v-model="searchParams.count" /> -->
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getClassifyURL, exportURL, classifySearchURL } from './api'

export default {
  name: 'DeviceConfigClassifyList',
  data () {
    return {
      searchUrl: getClassifyURL,
      exportUrl: exportURL,
      searchParams: {
        firstLevel: '',
        secondLevel: '',
        thirdLevel: '',
        startDate: '',
        endDate: ''
      },
      headers: [
        {
          prop: 'firstName',
          label: '一级分类'
        },
        {
          prop: 'secondName',
          label: '二级分类'
        },
        {
          prop: 'thirdName',
          label: '三级分类'
        },
        // {
        //   prop: 'count',
        //   label: '关联设备',
        //   align: 'right'
        // },
        // {
        //   prop: 'userName',
        //   label: '创建人'
        // },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ],
      firstLevelParams: {
        searchUrl: classifySearchURL,
        subjoin: {
          codeLevel: 1
        }
      },
      secondLevelParams: {
        searchUrl: classifySearchURL,
        subjoin: {
          codeLevel: 2
        }
      },
      thirdLevelParams: {
        searchUrl: classifySearchURL,
        subjoin: {
          codeLevel: 3
        }
      }
    }
  },
  methods: {
    // 关联通用内容
    relate () {
      this.$router.push({
        name: 'deviceConfigClassifyAdd'
      })
    },
    // 配置分类
    configClassify () {
      this.$router.push({
        name: 'deviceConfigClassifyConfigList'
      })
    },
    // 导入维保单位关联分类
    importMaintain () {
      this.$router.push({
        name: 'deviceConfigImportMaintain'
      })
    },
    // 导入通用内容并关联分类
    importCommon () {
      this.$router.push({
        name: 'deviceConfigImportCommon'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'deviceConfigClassifyEdit',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>
